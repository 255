// 履约公示相关
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
    preApi = '/mock';
}


//获取欠费单
export function getArrearsListByCondition(params) {
    return http({
        url: `${preApi}/performance/getArrearsListByCondition`,
        method: "post",
        data: params
    })
}
//增加欠费单
export function addPerformanceArrears(params) {
    return http({
        url: `${preApi}/performance/addArrears`,
        method: "post",
        data: params
    })
}
//删除一个欠费单
export function deleteOneArrear(params) {
    return http({
        url: `${preApi}/performance/deleteOneArrears`,
        method: "post",
        data: params
    })
}

//修改一个欠费单
export function editArrears(params) {
    return http({
        url: `${preApi}/performance/editArrears`,
        method: "post",
        data: params
    })
}

//获取违约单
export function getViolationsListByCondition(params) {
    return http({
        url: `${preApi}/performance/getViolationListByCondition`,
        method: "post",
        data: params
    })
}
//增加违约单
export function addPerformanceViolation(params) {
    return http({
        url: `${preApi}/performance/addViolation`,
        method: "post",
        data: params
    })
}
//删除一个违约单
export function deleteOneViolation(params) {
    return http({
        url: `${preApi}/performance/deleteOneViolation`,
        method: "post",
        data: params
    })
}

//修改一个违约单
export function editViolation(params) {
    return http({
        url: `${preApi}/performance/editViolation`,
        method: "post",
        data: params
    })
}


//获取服务及收费单
export function getServiceFeesListByCondition(params) {
    return http({
        url: `${preApi}/performance/getServiceFeesListByCondition`,
        method: "post",
        data: params
    })
}
//增加服务及收费单
export function addPerformanceServicesFees(params) {
    return http({
        url: `${preApi}/performance/addServiceFees`,
        method: "post",
        data: params
    })
}
//删除一个服务及收费单
export function deleteOneServiceFees(params) {
    return http({
        url: `${preApi}/performance/deleteOneServiceFees`,
        method: "post",
        data: params
    })
}

//修改一个服务及收费单
export function editServiceFees(params) {
    return http({
        url: `${preApi}/performance/editServiceFees`,
        method: "post",
        data: params
    })
}

//获取外委单位信息单
export function getOutUnitInfoListByCondition(params) {
    return http({
        url: `${preApi}/performance/getOutUnitInfoListByCondition`,
        method: "post",
        data: params
    })
}
//增加外委单位信息单
export function addPerformanceOutUnitInfo(params) {
    return http({
        url: `${preApi}/performance/addOutUnitInfo`,
        method: "post",
        data: params
    })
}
//删除一个外委单位信息单
export function deleteOneOutUnitInfo(params) {
    return http({
        url: `${preApi}/performance/deleteOneOutUnitInfo`,
        method: "post",
        data: params
    })
}

//修改一个外委单位信息单
export function editOutUnitInfo(params) {
    return http({
        url: `${preApi}/performance/editOutUnitInfo`,
        method: "post",
        data: params
    })
}

//获取四大文件信息单
export function getFileLookupListByCondition(params) {
    return http({
        url: `${preApi}/performance/getFileLookupListByCondition`,
        method: "post",
        data: params
    })
}
//增加四大文件信息单
export function addPerformanceFileLookup(params) {
    return http({
        url: `${preApi}/performance/addFileLookup`,
        method: "post",
        data: params
    })
}
//删除一个四大文件信息单
export function deleteOneFileLookup(params) {
    return http({
        url: `${preApi}/performance/deleteOneFileLookup`,
        method: "post",
        data: params
    })
}
//修改一个四大文件信息单
export function editFileLookup(params) {
    return http({
        url: `${preApi}/performance/editFileLookup`,
        method: "post",
        data: params
    })
}

//获取承接查验报告信息单
export function getUndertakeInspectionListByCondition(params) {
    return http({
        url: `${preApi}/performance/getUndertakeInspectionListByCondition`,
        method: "post",
        data: params
    })
}
//增加承接查验报告信息单
export function addPerformanceUndertakeInspection(params) {
    return http({
        url: `${preApi}/performance/addUndertakeInspection`,
        method: "post",
        data: params
    })
}
//删除一个承接查验报告信息单
export function deleteOneUndertakeInspection(params) {
    return http({
        url: `${preApi}/performance/deleteOneUndertakeInspection`,
        method: "post",
        data: params
    })
}
//修改一个承接查验报告信息单
export function editUndertakeInspection(params) {
    return http({
        url: `${preApi}/performance/editUndertakeInspection`,
        method: "post",
        data: params
    })
}


//获取服务企业信息单
export function getServeEnterpriseInfoListByCondition(params) {
    return http({
        url: `${preApi}/performance/getServeEnterpriseInfoListByCondition`,
        method: "post",
        data: params
    })
}
//增加服务企业信息单
export function addPerformanceServeEnterpriseInfo(params) {
    return http({
        url: `${preApi}/performance/addServeEnterpriseInfo`,
        method: "post",
        data: params
    })
}
//删除一个服务企业信息单
export function deleteOneServeEnterpriseInfo(params) {
    return http({
        url: `${preApi}/performance/deleteOneServeEnterpriseInfo`,
        method: "post",
        data: params
    })
}

//修改一个服务企业信息单
export function editServeEnterpriseInfo(params) {
    return http({
        url: `${preApi}/performance/editServeEnterpriseInfo`,
        method: "post",
        data: params
    })
}


//获取物业费收支单
export function getPropertyFeesListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyFeesListByCondition`,
        method: "post",
        data: params
    })
}
//增加物业费收支单
export function addPerformancePropertyFees(params) {
    return http({
        url: `${preApi}/performance/addPropertyFees`,
        method: "post",
        data: params
    })
}
//删除物业费收支单
export function deleteOnePropertyFees(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyFees`,
        method: "post",
        data: params
    })
}

//修改物业费收支单
export function editPropertyFees(params) {
    return http({
        url: `${preApi}/performance/editPropertyFees`,
        method: "post",
        data: params
    })
}


//获取公共收入收支单
export function getPublicRevenueListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPublicRevenueListByCondition`,
        method: "post",
        data: params
    })
}
//增加公共收入收支单
export function addPerformancePublicRevenue(params) {
    return http({
        url: `${preApi}/performance/addPublicRevenue`,
        method: "post",
        data: params
    })
}
//删除公共收入收支单
export function deleteOnePublicRevenue(params) {
    return http({
        url: `${preApi}/performance/deleteOnePublicRevenue`,
        method: "post",
        data: params
    })
}

//修改公共收入收支单
export function editPublicRevenue(params) {
    return http({
        url: `${preApi}/performance/editPublicRevenue`,
        method: "post",
        data: params
    })
}

//获取专项维修资金收支单
export function getSpecialMaintenanceFundsListByCondition(params) {
    return http({
        url: `${preApi}/performance/getSpecialMaintenanceFundsListByCondition`,
        method: "post",
        data: params
    })
}
//增加专项维修资金收支单
export function addPerformanceSpecialMaintenanceFunds(params) {
    return http({
        url: `${preApi}/performance/addSpecialMaintenanceFunds`,
        method: "post",
        data: params
    })
}
//删除专项维修资金收支单
export function deleteOneSpecialMaintenanceFunds(params) {
    return http({
        url: `${preApi}/performance/deleteOneSpecialMaintenanceFunds`,
        method: "post",
        data: params
    })
}

//修改专项维修资金收支单
export function editSpecialMaintenanceFunds(params) {
    return http({
        url: `${preApi}/performance/editSpecialMaintenanceFunds`,
        method: "post",
        data: params
    })
}


//获取专项服务工作单
export function getPropertyWorkSpecialListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyWorkSpecialListByCondition`,
        method: "post",
        data: params
    })
}
//增加专项服务工作单
export function addPerformancePropertyWorkSpecial(params) {
    return http({
        url: `${preApi}/performance/addPropertyWorkSpecial`,
        method: "post",
        data: params
    })
}
//删除专项服务工作单
export function deleteOnePropertyWorkSpecial(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyWorkSpecial`,
        method: "post",
        data: params
    })
}

//修改专项服务工作单
export function editPropertyWorkSpecial(params) {
    return http({
        url: `${preApi}/performance/editPropertyWorkSpecial`,
        method: "post",
        data: params
    })
}



//获取秩序维护及报事处理情况单
export function getPropertyWorkHandleListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyWorkHandleListByCondition`,
        method: "post",
        data: params
    })
}
//增加秩序维护及报事处理情况单
export function addPerformancePropertyWorkHandle(params) {
    return http({
        url: `${preApi}/performance/addPropertyWorkHandle`,
        method: "post",
        data: params
    })
}
//删除秩序维护及报事处理情况单
export function deleteOnePropertyWorkHandle(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyWorkHandle`,
        method: "post",
        data: params
    })
}

//修改秩序维护及报事处理情况单
export function editPropertyWorkHandle(params) {
    return http({
        url: `${preApi}/performance/editPropertyWorkHandle`,
        method: "post",
        data: params
    })
}


//获取绿化养护情况单
export function getPropertyWorkAfforestListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyWorkAfforestListByCondition`,
        method: "post",
        data: params
    })
}
//增加绿化养护情况单
export function addPerformancePropertyWorkAfforest(params) {
    return http({
        url: `${preApi}/performance/addPropertyWorkAfforest`,
        method: "post",
        data: params
    })
}
//删除绿化养护情况单
export function deleteOnePropertyWorkAfforest(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyWorkAfforest`,
        method: "post",
        data: params
    })
}

//修改绿化养护情况单
export function editPropertyWorkAfforest(params) {
    return http({
        url: `${preApi}/performance/editPropertyWorkAfforest`,
        method: "post",
        data: params
    })
}

//获取消杀与白蚁防治单
export function getPropertyWorkSterilizeListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyWorkSterilizeListByCondition`,
        method: "post",
        data: params
    })
}
//增加消杀与白蚁防治单
export function addPerformancePropertyWorkSterilize(params) {
    return http({
        url: `${preApi}/performance/addPropertyWorkSterilize`,
        method: "post",
        data: params
    })
}
//删除消杀与白蚁防治单
export function deleteOnePropertyWorkSterilize(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyWorkSterilize`,
        method: "post",
        data: params
    })
}

//修改消杀与白蚁防治单
export function editPropertyWorkSterilize(params) {
    return http({
        url: `${preApi}/performance/editPropertyWorkSterilize`,
        method: "post",
        data: params
    })
}

//获取清洁服务情况单
export function getPropertyWorkCleaningListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyWorkCleaningListByCondition`,
        method: "post",
        data: params
    })
}
//增加清洁服务情况单
export function addPerformancePropertyWorkCleaning(params) {
    return http({
        url: `${preApi}/performance/addPropertyWorkCleaning`,
        method: "post",
        data: params
    })
}
//删除清洁服务情况单
export function deleteOnePropertyWorkCleaning(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyWorkCleaning`,
        method: "post",
        data: params
    })
}

//修改清洁服务情况单
export function editPropertyWorkCleaning(params) {
    return http({
        url: `${preApi}/performance/editPropertyWorkCleaning`,
        method: "post",
        data: params
    })
}



//获取垃圾处理单
export function getPropertyWorkWasteListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyWorkWasteListByCondition`,
        method: "post",
        data: params
    })
}
//增加垃圾处理单
export function addPerformancePropertyWorkWaste(params) {
    return http({
        url: `${preApi}/performance/addPropertyWorkWaste`,
        method: "post",
        data: params
    })
}
//删除垃圾处理单
export function deleteOnePropertyWorkWaste(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyWorkWaste`,
        method: "post",
        data: params
    })
}

//修改垃圾处理单
export function editPropertyWorkWaste(params) {
    return http({
        url: `${preApi}/performance/editPropertyWorkWaste`,
        method: "post",
        data: params
    })
}


//获取设施设备运行维修保养情况单
export function getPropertyWorkDeviceListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyWorkDeviceListByCondition`,
        method: "post",
        data: params
    })
}
//增加设施设备运行维修保养情况单
export function addPerformancePropertyWorkDevice(params) {
    return http({
        url: `${preApi}/performance/addPropertyWorkDevice`,
        method: "post",
        data: params
    })
}
//删除设施设备运行维修保养情况单
export function deleteOnePropertyWorkDevice(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyWorkDevice`,
        method: "post",
        data: params
    })
}

//修改设施设备运行维修保养情况单
export function editPropertyWorkDevice(params) {
    return http({
        url: `${preApi}/performance/editPropertyWorkDevice`,
        method: "post",
        data: params
    })
}


//获取承接查验问题整改情况单
export function getPropertyUndertakeInspectionListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyUndertakeInspectionListByCondition`,
        method: "post",
        data: params
    })
}
//增加承接查验问题整改情况单
export function addPerformancePropertyUndertakeInspection(params) {
    return http({
        url: `${preApi}/performance/addPropertyUndertakeInspection`,
        method: "post",
        data: params
    })
}
//删除承接查验问题整改情况单
export function deleteOnePropertyUndertakeInspection(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyUndertakeInspection`,
        method: "post",
        data: params
    })
}

//修改承接查验问题整改情况单
export function editPropertyUndertakeInspection(params) {
    return http({
        url: `${preApi}/performance/editPropertyUndertakeInspection`,
        method: "post",
        data: params
    })
}

//获取项目巡查情况单
export function getPropertyProjectInspectionListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyProjectInspectionListByCondition`,
        method: "post",
        data: params
    })
}
//增加项目巡查情况单
export function addPerformancePropertyProjectInspection(params) {
    return http({
        url: `${preApi}/performance/addPropertyProjectInspection`,
        method: "post",
        data: params
    })
}
//删除项目巡查情况单
export function deleteOnePropertyProjectInspection(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyProjectInspection`,
        method: "post",
        data: params
    })
}

//修改项目巡查情况单
export function editPropertyProjectInspection(params) {
    return http({
        url: `${preApi}/performance/editPropertyProjectInspection`,
        method: "post",
        data: params
    })
}


//获取调度中心工作情况单
export function getPropertyDispatchCenterListByCondition(params) {
    return http({
        url: `${preApi}/performance/getPropertyDispatchCenterListByCondition`,
        method: "post",
        data: params
    })
}
//增加调度中心工作情况单
export function addPerformancePropertyDispatchCenter(params) {
    return http({
        url: `${preApi}/performance/addPropertyDispatchCenter`,
        method: "post",
        data: params
    })
}
//删除调度中心工作情况单
export function deleteOnePropertyDispatchCenter(params) {
    return http({
        url: `${preApi}/performance/deleteOnePropertyDispatchCenter`,
        method: "post",
        data: params
    })
}

//修改调度中心工作情况单
export function editPropertyDispatchCenter(params) {
    return http({
        url: `${preApi}/performance/editPropertyDispatchCenter`,
        method: "post",
        data: params
    })
}


//获取项目情况简介--绿化单
export function getProjectInfoAfforestListByCondition(params) {
    return http({
        url: `${preApi}/performance/getProjectInfoAfforestListByCondition`,
        method: "post",
        data: params
    })
}
//增加项目情况简介--绿化单
export function addPerformanceProjectInfoAfforest(params) {
    return http({
        url: `${preApi}/performance/addProjectInfoAfforest`,
        method: "post",
        data: params
    })
}
//删除项目情况简介--绿化单
export function deleteOneProjectInfoAfforest(params) {
    return http({
        url: `${preApi}/performance/deleteOneProjectInfoAfforest`,
        method: "post",
        data: params
    })
}

//修改项目情况简介--绿化单
export function editProjectInfoAfforest(params) {
    return http({
        url: `${preApi}/performance/editProjectInfoAfforest`,
        method: "post",
        data: params
    })
}


//获取项目情况简介--停车场单
export function getProjectInfoParkListByCondition(params) {
    return http({
        url: `${preApi}/performance/getProjectInfoParkListByCondition`,
        method: "post",
        data: params
    })
}
//增加项目情况简介--停车场单
export function addPerformanceProjectInfoPark(params) {
    return http({
        url: `${preApi}/performance/addProjectInfoPark`,
        method: "post",
        data: params
    })
}
//删除项目情况简介--停车场单
export function deleteOneProjectInfoPark(params) {
    return http({
        url: `${preApi}/performance/deleteOneProjectInfoPark`,
        method: "post",
        data: params
    })
}

//修改项目情况简介--停车场单
export function editProjectInfoPark(params) {
    return http({
        url: `${preApi}/performance/editProjectInfoPark`,
        method: "post",
        data: params
    })
}


//获取项目情况简介--白蚁防治单
export function getProjectInfoTermiteControlListByCondition(params) {
    return http({
        url: `${preApi}/performance/getProjectInfoTermiteControlListByCondition`,
        method: "post",
        data: params
    })
}
//增加项目情况简介--白蚁防治单
export function addPerformanceProjectInfoTermiteControl(params) {
    return http({
        url: `${preApi}/performance/addProjectInfoTermiteControl`,
        method: "post",
        data: params
    })
}
//删除项目情况简介--白蚁防治单
export function deleteOneProjectInfoTermiteControl(params) {
    return http({
        url: `${preApi}/performance/deleteOneProjectInfoTermiteControl`,
        method: "post",
        data: params
    })
}

//修改项目情况简介--白蚁防治单
export function editProjectInfoTermiteControl(params) {
    return http({
        url: `${preApi}/performance/editProjectInfoTermiteControl`,
        method: "post",
        data: params
    })
}


//获取项目情况简介--共用设施设备单
export function getProjectInfoShareListByCondition(params) {
    return http({
        url: `${preApi}/performance/getProjectInfoShareListByCondition`,
        method: "post",
        data: params
    })
}
//增加项目情况简介--共用设施设备单
export function addPerformanceProjectInfoShare(params) {
    return http({
        url: `${preApi}/performance/addProjectInfoShare`,
        method: "post",
        data: params
    })
}
//删除项目情况简介--共用设施设备单
export function deleteOneProjectInfoShare(params) {
    return http({
        url: `${preApi}/performance/deleteOneProjectInfoShare`,
        method: "post",
        data: params
    })
}

//修改项目情况简介--共用设施设备单
export function editProjectInfoShare(params) {
    return http({
        url: `${preApi}/performance/editProjectInfoShare`,
        method: "post",
        data: params
    })
}


//获取项目情况简介--消杀单
export function getProjectInfoSterilizeListByCondition(params) {
    return http({
        url: `${preApi}/performance/getProjectInfoSterilizeListByCondition`,
        method: "post",
        data: params
    })
}
//增加项目情况简介--消杀单
export function addPerformanceProjectInfoSterilize(params) {
    return http({
        url: `${preApi}/performance/addProjectInfoSterilize`,
        method: "post",
        data: params
    })
}
//删除项目情况简介--消杀单
export function deleteOneProjectInfoSterilize(params) {
    return http({
        url: `${preApi}/performance/deleteOneProjectInfoSterilize`,
        method: "post",
        data: params
    })
}

//修改项目情况简介--消杀单
export function editProjectInfoSterilize(params) {
    return http({
        url: `${preApi}/performance/editProjectInfoSterilize`,
        method: "post",
        data: params
    })
}

//获取项目情况简介--清洁单
export function getProjectInfoCleaningListByCondition(params) {
    return http({
        url: `${preApi}/performance/getProjectInfoCleaningListByCondition`,
        method: "post",
        data: params
    })
}
//增加项目情况简介--清洁单
export function addPerformanceProjectInfoCleaning(params) {
    return http({
        url: `${preApi}/performance/addProjectInfoCleaning`,
        method: "post",
        data: params
    })
}
//删除项目情况简介--清洁单
export function deleteOneProjectInfoCleaning(params) {
    return http({
        url: `${preApi}/performance/deleteOneProjectInfoCleaning`,
        method: "post",
        data: params
    })
}

//修改项目情况简介--清洁单
export function editProjectInfoCleaning(params) {
    return http({
        url: `${preApi}/performance/editProjectInfoCleaning`,
        method: "post",
        data: params
    })
}


//获取项目情况简介--项目建设规模单
export function getProjectInfoScaleListByCondition(params) {
    return http({
        url: `${preApi}/performance/getProjectInfoScaleListByCondition`,
        method: "post",
        data: params
    })
}
//增加项目情况简介--项目建设规模单
export function addPerformanceProjectInfoScale(params) {
    return http({
        url: `${preApi}/performance/addProjectInfoScale`,
        method: "post",
        data: params
    })
}
//删除项目情况简介--项目建设规模单
export function deleteOneProjectInfoScale(params) {
    return http({
        url: `${preApi}/performance/deleteOneProjectInfoScale`,
        method: "post",
        data: params
    })
}

//修改项目情况简介--项目建设规模单
export function editProjectInfoScale(params) {
    return http({
        url: `${preApi}/performance/editProjectInfoScale`,
        method: "post",
        data: params
    })
}

// 一键生成履约报告
export function exportPerformanceReport(params) {
    params.filename = '履约报告.docx';
    return http({
        url: `${preApi}/performance/exportPerformanceReport`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}

//自动生成报告--查询
export function getAutoReportListByCondition(params) {
    return http({
        url: `${preApi}/performance/getAutoReportListByCondition`,
        method: "post",
        data: params
    })
}
//自动生成报告-增加
export function addPerformanceAutoReport(params) {
    return http({
        url: `${preApi}/performance/addAutoReport`,
        method: "post",
        data: params
    })
}
//自动生成报告-删除
export function deleteOneAutoReport(params) {
    return http({
        url: `${preApi}/performance/deleteOneAutoReport`,
        method: "post",
        data: params
    })
}

//自动生成报告-删除
export function cancelOneAutoReport(params) {
    return http({
        url: `${preApi}/performance/cancelOneAutoReport`,
        method: "post",
        data: params
    })
}

//自动生成报告--编辑
export function editAutoReport(params) {
    return http({
        url: `${preApi}/performance/editAutoReport`,
        method: "post",
        data: params
    })
}

//自动生成报告--依据记录id更新确认状态
export function updateConfirmStatusById(params) {
    return http({
        url: `${preApi}/performance/updateConfirmStatusById`,
        method: "post",
        data: params
    })
}

//自动生成报告--依据记录文件报告名称更新确认状态
export function updateConfirmStatusByFilepath(params) {
    return http({
        url: `${preApi}/performance/updateConfirmStatusByFilepath`,
        method: "post",
        data: params
    })
}


//获取通知公告
export function getNotificationListByCondition(params) {
    return http({
        url: `${preApi}/performance/getNotificationListByCondition`,
        method: "post",
        data: params
    })
}
//增加通知公告
export function addPerformanceNotification(params) {
    return http({
        url: `${preApi}/performance/addNotification`,
        method: "post",
        data: params
    })
}
//删除通知公告
export function deleteOneNotification(params) {
    return http({
        url: `${preApi}/performance/deleteOneNotification`,
        method: "post",
        data: params
    })
}
//修改通知公告
export function editNotification(params) {
    return http({
        url: `${preApi}/performance/editNotification`,
        method: "post",
        data: params
    })
}
//将通知公告置为历史
export function setNotificationHistory(params) {
    return http({
        url: `${preApi}/performance/setNotificationHistory`,
        method: "post",
        data: params
    })
}
//查询通知组列表
export function getNotificationGroupList(params) {
    return http({
        url: `${preApi}/performance/getNotificationGroupList`,
        method: "post",
        data: params
    })
}


//获取通知组
export function getNotificationGroupUserListByCondition(params) {
    return http({
        url: `${preApi}/performance/getNotificationGroupUserListByCondition`,
        method: "post",
        data: params
    })
}
//增加通知组
export function addPerformanceNotificationGroupUser(params) {
    return http({
        url: `${preApi}/performance/addNotificationGroupUser`,
        method: "post",
        data: params
    })
}
//删除通知组
export function deleteOneNotificationGroupUser(params) {
    return http({
        url: `${preApi}/performance/deleteOneNotificationGroupUser`,
        method: "post",
        data: params
    })
}
//修改通知组
export function editNotificationGroupUser(params) {
    return http({
        url: `${preApi}/performance/editNotificationGroupUser`,
        method: "post",
        data: params
    })
}
//将通知组置为历史
export function setNotificationGroupUserHistory(params) {
    return http({
        url: `${preApi}/performance/setNotificationGroupUserHistory`,
        method: "post",
        data: params
    })
}


//获取通知
export function getNotificationGroupListByCondition(params) {
    return http({
        url: `${preApi}/performance/getNotificationGroupListByCondition`,
        method: "post",
        data: params
    })
}
//增加通知
export function addPerformanceNotificationGroup(params) {
    return http({
        url: `${preApi}/performance/addNotificationGroup`,
        method: "post",
        data: params
    })
}
//删除通知
export function deleteOneNotificationGroup(params) {
    return http({
        url: `${preApi}/performance/deleteOneNotificationGroup`,
        method: "post",
        data: params
    })
}
//修改通知
export function editNotificationGroup(params) {
    return http({
        url: `${preApi}/performance/editNotificationGroup`,
        method: "post",
        data: params
    })
}

//确认物业费收支单
export function updatePropertyFeesConfirmStatusById(params) {
    return http({
        url: `${preApi}/performance/updatePropertyFeesConfirmStatusById`,
        method: "post",
        data: params
    })
}
//确认公共收入费收支单
export function updatePublicRevenueConfirmStatusById(params) {
    return http({
        url: `${preApi}/performance/updatePublicRevenueConfirmStatusById`,
        method: "post",
        data: params
    })
}
//确认专项维修资金收支单
export function updateSpecialMaintenanceFundsConfirmStatusById(params) {
    return http({
        url: `${preApi}/performance/updateSpecialMaintenanceFundsConfirmStatusById`,
        method: "post",
        data: params
    })
}